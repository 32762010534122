import React, { useEffect, useState } from 'react';
import Nav from '~/components/Nav';
import Head from 'next/head';

import ErrorPage from '@zola/zola-ui/src/components/ErrorPage';
import { getNav } from '~/libs/client/nav/api';
import { OuterContainer } from '~/components/CustomErrorPage/CustomErrorPage.styles';
import dynamic from 'next/dynamic';

const Footer = dynamic(import('~/components/ui/Footer/Footer'), {
  ssr: false,
});

export type CustomErrorPageProps = {
  statusCode: number;
};

const CustomErrorPage = ({ statusCode }: CustomErrorPageProps): JSX.Element => {
  const [navHTML, setNavHTML] = useState('');
  const pageMetaTitle = statusCode === 404 ? 'Not found' : 'Error occurred';
  useEffect(() => {
    getNav()
      .then(html => setNavHTML(html))
      .catch(() => {
        // eslint-disable-next-line no-console
        console.error(`Error loading nav on ${statusCode} page`);
      });
  }, [statusCode]);

  return (
    <OuterContainer>
      <Head>
        <title>{pageMetaTitle} - Zola</title>
      </Head>
      <Nav navHTML={navHTML} user={null} showPromo ssr={false} />
      <ErrorPage statusCode={statusCode} />
      <Footer />
    </OuterContainer>
  );
};

export default CustomErrorPage;
